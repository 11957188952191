<template>
  <div style="display: flex">
    <div class="search_input_wrapper">
      <div class="search_type">
        <el-select style="width: 106px" v-model="searchType" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
        <div class="search_line"></div>
      </div>
      <div class="search_input">
        <input v-model="value" placeholder="请输入关键词" type="text" style="width: 100%; height: 100%; padding: 11px 18px" />
      </div>
      <div class="search_btn" @click="clickSearch">
        <img
          src="https://staticscdn.zgzpsjz.com/new_yupao_pc/images/wyh/top_icon_search.png"
          style="display: inline-block; width: 16px; height: 16px; margin-right: 8px"
        />
        <p style="font-size: 16px; font-weight: 700; line-height: 22px">搜索</p>
      </div>
    </div>
    <div class="issue_button_wrapper">
      <div class="issue_button_content"><i class="el-icon-s-promotion" style="margin-right: 10px"></i>发布招工</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchType: '1',
      value: "",
      options: [
        {
          value: '1',
          label: '找活干'
        },
        {
          value: '2',
          label: '找工人'
        }
      ]
    }
  },
  mounted() {
    console.log("时间被处罚", this.value);
  },
  methods: {
    clickSearch() {
      if (this.searchType == 1) {
        this.$router.push('/job')
      } else {
        this.$router.push('/worker')
      }
    }
  }
}
</script>
<style scoped lang="less">
.el-select /deep/ .el-input__inner {
  border: none;
}
.search_input_wrapper {
  width: 770px;
  height: 56px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  border: 2px solid #42c088;
  border-right: none;
  .search_btn {
    width: 144px;
    height: 56px;
    background-color: #42c088;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 0 4px 4px 0;
  }
  .search_type {
    height: 100%;
    padding: 6px 0;
    text-align: center;
    line-height: 40px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 18px;
    background-color: #fff;
    border-radius: 4px 0 0 4px;
    .search_line {
      display: inline-block;
      min-width: 1px;
      max-width: 1px;
      height: 100%;
      border-left: 1px solid #eff1f6;
      vertical-align: middle;
      background-color: #fff;
    }
  }
  .search_input {
    flex: 1 1;
    width: 100%;
    height: 100%;
  }
}
.issue_button_wrapper {
  display: inline-block;
  padding: 0 10px;
  background: #42c088;
  border-radius: 4px;
  cursor: pointer;
  .issue_button_content {
    display: inline-flex;
    height: 56px;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #fff;
    letter-spacing: 0;
    font-weight: 700;
  }
}
</style>
