<template>
  <div style="background-color: #fff; width: 100%">
    <div class="worker_item">
      <div class="worker_item_card">
        <p class="card_title">
          <img
            src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
            style="width: 40px; height: 40px; margin-right: 10px"
          />
          <span class="card_title_text">{{ detail.contact }}</span>
        </p>
        <div class="card_content">
          <div class="card_info">
            <!-- <div class="card_price">6000-7000元/月</div> -->
            <div class="card_time">
              {{ detail.content }}
            </div>
          </div>
          <div style="display: flex; align-items: center">
            <div class="card_tag_list">
              <div class="card_tag_item">{{ detail.city }}</div>
              <div class="card_tag_item">{{ detail.type }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="worker_bottom">
        <span class="bottom_left">
          <!-- <span class="left_user_name">{{ detail.contact }}</span> -->
          <span style="display: flex">
            <span style="background-color: #fff" class="YpLabel_ypLabel__2VFW5 RecruitRecommendCard_label-smitem__TCFK4"
              >实名</span
            >
            <!-- <span class="YpLabel_ypLabel__2VFW5 RecruitRecommendCard_label-qyitem__cGFBJ">企业</span> -->
          </span>
        </span>
        <div class="bottom_right">
          <button class="right_button">立即联系</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  props: {
    detail: {
      type: Object,
      default: () => {}
    },
    type: {
      type: Number,
      default: 1
    }
  }
}
</script>
<style scoped lang="less">
.worker_item {
  margin-bottom: 15px;
  width: 390px;
  height: auto;
  background-color: #fff;
  position: relative;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    box-shadow: 0 0 12px 0 rgba(50, 52, 60, 0.08);
  }
  .worker_item_card {
    padding: 16px;
    display: block;
    width: 100%;
    .card_title {
      width: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
      margin-bottom: 10px;
      .card_title_text {
        display: block;
        font-size: 18px;
        flex: 1 1;
        color: #111;
        letter-spacing: 0;
        padding-right: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .card_content {
      // .icon-style {
      //   display: flex !important;
      //   flex-direction: column !important;
      //   align-items: flex-start !important;
      // }
      .card_info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        // margin-bottom: 9px;
        line-height: 28px;
        .card_price {
          color: #42c088;
          font-size: 18px;
          font-weight: 600;
        }
        .card_time {
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.45);
        }
        .card_img {
          width: 130px;
          height: 130px;
          border-radius: 8px;
          overflow: hidden;
          margin-bottom: 15px;
          .card_img_style {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .card_tag_list {
      flex: 1 1;
      display: flex;
      flex-wrap: wrap;
      height: 24px;
      overflow: hidden;
      .card_tag_item {
        flex-shrink: 0;
        width: -moz-fit-content;
        width: fit-content;
        max-width: 100px;
        height: 24px;
        font-size: 12px;
        line-height: 24px;
        padding: 0 8px;
        background: #f5f6fa;
        color: rgba(0, 0, 0, 0.65);
        border-radius: 2px;
        margin-right: 8px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
  .worker_bottom {
    width: 100%;
    height: 44px;
    background: linear-gradient(270deg, #fff, #f2f9ff);
    border-radius: 0 0 4px 4px;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .bottom_left {
      display: flex;
      align-items: center;
      .left_user_name {
        color: rgba(0, 0, 0, 0.65);
        max-width: 66px;
        padding: 0 4px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .YpLabel_ypLabel__2VFW5 {
        display: inline-block;
        padding: 0 6px;
        font-size: 12px;
        font-weight: 500;
        margin-right: 10px;
        border-radius: 2px;
        flex-shrink: 0;
      }
      .RecruitRecommendCard_label-smitem__TCFK4 {
        color: #46db7a !important;
        border: 1px solid #cfefd9;
        height: 20px;
        margin-right: 8px;
        background-color: #fff;
      }
      .RecruitRecommendCard_label-qyitem__cGFBJ {
        color: #0092ff !important;
        border: 1px solid #c8e7ff;
        height: 20px;
        margin-right: 8px;
      }
    }
    .bottom_right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .right_button {
        width: 90px;
        height: 32px;
        border-radius: 4px;
        background-color: #fff;
        color: #42c088;
        border: 1px solid #42c088;
      }
    }
  }
}
</style>
