<template>
  <div class="home_wrapper">
    <div class="home_login_wrapper" v-if="!isLogin">
      <div class="home_login_background"></div>
      <div class="home_login_content">
        <div class="home_login_content_form">
          <input type="text" v-model="loginForm.mobile" placeholder="请输入手机号" class="login_form_input" />
          <div class="login_code_wrapper">
            <input type="text" v-model="loginForm.code" style="width: 100%; height: 100%; flex: 1 1" />
            <div
              @click="getCode"
              :style="{ 'pointer-events': countdown < 60 ? 'none' : '' }"
              style="
                position: relative;
                padding-left: 12px;
                font-size: 13px;
                color: #42c088;
                font-weight: 400;
                line-height: 44px;
                cursor: pointer;
              "
            >
              {{ currentLoginBtn }}
            </div>
          </div>
          <div class="login_button" @click="clickLogin">登录/注册</div>
        </div>
      </div>
    </div>
    <div class="home_main_wrapper">
      <div class="home_search_wrapper">
        <search ref="search"></search>
      </div>
      <div class="home_category_wrapper">
        <div class="home_category_list">
          <div class="home_category_list_content">
            <div
              class="category_item"
              v-for="(item, index) in categoryList"
              :key="index"
              @click="handleCategory(item.code)"
            >
              <div class="category_item_content">
                <span class="category_text">{{ item.title }}</span>
                <i class="el-icon-caret-right" style="color: #bfbfbf"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="home_category_left">
          <img src="@/assets/images/left_01.png" style="width: 100%; height: 280px; border-radius: 5px" />
          <div class="ant_carousel">
            <div class="slick_slider">
              <img src="@/assets/images/left_02.png" style="width: 100%; height: 100%; border-radius: 5px" />
            </div>
            <!-- <img /> -->
          </div>
        </div>
        <div class="home_category_right">
          <div class="right_img_wrapper">
            <img src="@/assets/images/right_01.png" style="width: 100%; height: 119px" />
          </div>
          <div class="right_img_wrapper">
            <img src="@/assets/images/right_02.png" style="width: 100%; height: 119px" />
          </div>
          <div class="right_img_wrapper">
            <img src="@/assets/images/right_03.png" style="width: 100%; height: 119px" />
          </div>
          <div class="right_img_wrapper">
            <img src="@/assets/images/right_04.png" style="width: 100%; height: 119px" />
          </div>
        </div>
      </div>
      <div class="home_worker_wrapper">
        <div class="home_worker_title">最新招工</div>
        <el-tabs v-model="paginationForm.hire" @tab-click="clickTab">
          <el-tab-pane label="点工" name="1"> </el-tab-pane>
          <el-tab-pane label="全职" name="5"></el-tab-pane>
          <el-tab-pane label="分包" name="2"></el-tab-pane>
        </el-tabs>
        <el-row :gutter="15">
          <el-col :span="6" v-for="(item, index) in zhaoGongList.slice(0, 9)" :key="index">
            <workItem :detail="item" @click.native="clickZhaoGongDetail(item, 1)"></workItem>
          </el-col>
        </el-row>
        <div style="width: 100%; margin-top: 16px; text-align: center">
          <button class="more_btn" @click="clickZhaogongMore">查看更多</button>
        </div>
      </div>
      <div class="home_worker_wrapper">
        <div class="home_worker_title">活跃工友</div>
        <el-row :gutter="15">
          <el-col
            :span="6"
            v-for="(item, index) in gongYouList"
            @click.native="clickZhaoGongDetail(item, 2)"
            :key="index"
          >
            <workerItem :detail="item"
          /></el-col>
        </el-row>
        <div style="width: 100%; margin-top: 16px; text-align: center">
          <button class="more_btn" @click="clickWorkerMore">查看更多</button>
        </div>
      </div>
      <div class="home_worker_wrapper">
        <div class="home_worker_title">弱电链</div>
        <el-row :gutter="15">
          <el-col
            :span="6"
            v-for="(item, index) in ruoDianList"
            @click.native="clickZhaoGongDetail(item, 3)"
            :key="index"
          >
            <workerItem :detail="item" :type="3"
          /></el-col>
        </el-row>
        <div style="width: 100%; margin-top: 16px; text-align: center">
          <button class="more_btn" @click="clickRuoDianMore">查看更多</button>
        </div>
      </div>
    </div>
    <loginModel ref="loginModelRef" />
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import search from '@/components/search'
import workItem from '@/components/workItem'
import workerItem from '@/components/workerItem'
import loginModel from '@/components/loginModel'
import { getList, getCode, getLogin } from '../../api/home.js'
export default {
  data() {
    return {
      countdown: 60,
      categoryList: [
        {
          title: '全部弱电工职位',
          code: ''
        },
        {
          title: '建筑智能化',
          code: '智能化'
        },
        {
          title: '网络工程',
          code: '网络'
        },
        {
          title: '安防监控',
          code: '监控、门禁'
        },
        {
          title: '通信工程',
          code: '通信'
        },
        {
          title: '消防电工程',
          code: '消防'
        },
        {
          title: 'IT运维',
          code: '电脑、维修'
        },
        {
          title: '自动化',
          code: '自动化、PLC'
        },
        {
          title: '综合布线',
          code: '布线'
        },
        {
          title: '光伏工程',
          code: '光伏'
        },
        {
          title: '充电桩',
          code: '充电桩'
        }
      ],

      activeName: 'first',

      paginationForm: {
        hire: '1',
        method: 'home.look_zhao_huo',
        page: 1,
        key: '',
        total: 0,
        city: '全国'
      },

      paginationForm2: {
        hire: '4',
        method: 'home.look_zhao_gong',
        page: 1,
        key: '',
        total: 0,
        city: '全国'
      },

      paginationForm3: {
        hire: '0',
        method: 'home.lookRuodianlian',
        page: 1,
        key: '',
        total: 0,
        city: '全国'
      },

      loginForm: {
        mobile: '',
        code: ''
      },

      zhaoGongList: [],

      gongYouList: [],
      searchVal: '',

      ruoDianList: []
    }
  },
  components: {
    search,
    workItem,
    workerItem,
    loginModel
  },
  created() {
    this.paginationForm.city = this.city[this.city.length - 1]
    // this.paginationForm.city = this.city[this.city.length - 1]
    this.getZhaoGongList()
    this.getGongYouList()
    this.getRuoDianList()
  },
  computed: {
    ...mapState({
      city: (state) => state.city,
      isLogin: (state) => state.isLogin,
      userInfo: (state) => state.userInfo
    }),
    currentLoginBtn() {
      return this.countdown < 60 ? `${this.countdown} 秒后重新获取` : '获取验证码'
    }
  },
  methods: {
    ...mapMutations(['changeIsLogin', 'changeUserInfo']),
    clickLogin() {
      getLogin({
        code: this.loginForm.code,
        method: 'login.AppLogin',
        phone: this.loginForm.mobile,
        session_key: '',
        uid: 0,
        user_id: 0
      }).then((res) => {
        if (res.code == 1) {
          this.changeIsLogin(true)
          this.changeUserInfo(res.data)
          this.$message.success('登录成功')
          console.log('clickLogin', res)
        }
      })
    },
    getCode() {
      let reg = /^1[3456789]\d{9}$/
      console.log('getCode', !reg.test(this.loginForm.mobile))
      if (!reg.test(this.loginForm.mobile)) {
        return this.$message.warning('手机号格式错误')
      } else {
        getCode({
          phone: this.loginForm.mobile,
          session_key: '',
          uid: 0,
          user_id: 0
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success('验证码获取成功')
          }
          console.log('getCode', res)
        })
        this.startTimer()
      }
    },
    handleCategory(val) {
      let result = val
      let searchDom = this.$refs.search
      if (searchDom) {
        searchDom.value = result
        this.$nextTick(function () {
          searchDom.clickSearch()
        })
      }
    },
    startTimer() {
      const interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--
        } else {
          clearInterval(interval)
          this.countdown = 60
        }
      }, 1000)
    },

    clickZhaoGongDetail(item, type) {
      if (!this.isLogin) {
        this.$refs.loginModelRef.dialogVisible = true
      } else {
        if (type == 1) {
          this.$router.push({
            path: '/jobDetail',
            query: {
              id: item.id
            }
          })
        } else if (type == 2) {
          this.$router.push({
            path: '/workerDetail',
            query: {
              id: item.id
            }
          })
        } else if (type == 3) {
          this.$router.push({
            path: '/weakCurrentDetail',
            query: {
              id: item.id
            }
          })
        }
      }
    },
    clickZhaogongMore() {
      const url = this.$router.resolve({ path: '/job' })
      window.open(url.href, '_blank')
    },
    clickWorkerMore() {
      const url = this.$router.resolve({ path: '/worker' })
      window.open(url.href, '_blank')
    },
    clickRuoDianMore() {
      const url = this.$router.resolve({ path: '/weakCurrent' })
      window.open(url.href, '_blank')
    },
    clickTab(value) {
      this.paginationForm.hire = value.name
      this.getZhaoGongList()
    },
    getZhaoGongList() {
      getList(this.paginationForm).then((res) => {
        this.zhaoGongList = res.data.list
        console.log('getZhaoGongList', res)
      })
    },
    getGongYouList() {
      getList(this.paginationForm2).then((res) => {
        this.gongYouList = res.data.list.splice(0, 9)
        console.log('getGongYouList', res)
      })
    },
    getRuoDianList() {
      getList(this.paginationForm3).then((res) => {
        this.ruoDianList = res.data.list.splice(0, 9)
      })
    }
  }
}
</script>
<style scoped lang="less">
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

.more_btn {
  width: 200px;
  height: 56px;
  font-size: 18px;
  display: inline-block;
  position: relative;
  outline: none;
  font-weight: 400;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.1s linear;
  box-sizing: border-box;
  line-height: 1.5715;
  background-color: #42c088;
  color: #fff;
  border: 1px solid transparent;
  border-radius: 2px;
}

::v-deep .el-tabs__item {
  font-size: 18px;
  padding: 0 40px;
}
::v-deep .el-tabs__nav-wrap::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #f0f0f0;
  z-index: 1;
}
::v-deep .el-tabs__item.is-active {
  color: #42c088;
}
::v-deep .el-tabs__active-bar {
  background-color: #42c088;
}
::v-deep .el-tabs__item:hover {
  color: #42c088;
}
::v-deep .el-col-6 {
  width: auto !important;
}
.home_wrapper {
  .home_login_wrapper {
    position: relative;
    width: 100%;
    height: 128px;
    display: flex;
    justify-content: center;
    background-color: #42c088;
    .home_login_background {
      overflow: hidden;
      background-image: url(https://staticscdn.zgzpsjz.com/new_yupao_pc/images/zz/ing_sy_banner.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 100%;
      background-position: 50%;
      background-size: cover;
    }
    .home_login_content {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      justify-content: flex-end;
      width: 1200px;
      .home_login_content_form {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 560px;
        height: 128px;
        .login_form_input {
          width: 200px;
          height: 44px;
          padding: 0 12px;
          color: rgba(0, 0, 0, 0.85);
          background: #fff;
          border-radius: 4px;
        }
        .login_code_wrapper {
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 220px;
          height: 44px;
          padding: 0 12px;
          color: rgba(0, 0, 0, 0.85);
          background: #fff;
          border-radius: 4px;
        }
        .login_button {
          width: 128px;
          height: 44px;
          border: 1px solid #fff;
          text-align: center;
          font-size: 16px;
          font-weight: 700;
          color: hsla(0, 0%, 100%, 0.95);
          line-height: 42px;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }
  .home_main_wrapper {
    position: relative;
    width: 100%;
    .home_search_wrapper {
      margin: 40px auto 0;
      width: fit-content;
      display: flex;
    }
    .home_category_wrapper {
      width: 1200px;
      margin: 40px auto 16px;
      display: flex;
      .home_category_list {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 384px;
        // height: 460px;
        background-color: #fff;
        border-radius: 4px;
        .home_category_list_content {
          width: 100%;
          height: 100%;
          padding: 8px 0;
          overflow: hidden;
          .category_item {
            cursor: pointer;
            width: 100%;
            height: 44px;
            line-height: 44px;
            padding: 0 4px;
            .category_item_content {
              position: relative;
              height: 44px;
              padding: 0 12px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              display: flex;
              justify-content: space-between;
              align-items: center;
              &:hover {
                box-shadow: 0 0 8px 0 #ebedf5;
              }
              .category_text {
                color: hsla(0, 0%, 7%, 0.85);
                font-size: 14px;
                font-weight: 700;
                font-family: PingFangSC-Regular;
              }
            }
          }
        }
      }
      .home_category_left {
        width: 500px;
        height: 100%;
        margin-left: 8px;
        .ant_carousel {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
          color: rgba(0, 0, 0, 0.85);
          font-size: 14px;
          font-variant: tabular-nums;
          line-height: 1.5715;
          list-style: none;
          font-feature-settings: 'tnum';
          .slick_slider {
            position: relative;
            display: block;
            box-sizing: border-box;
            touch-action: pan-y;
            -webkit-touch-callout: none;
            margin-top: 8px;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          }
        }
      }
      .home_category_right {
        width: 299px;
        height: 100%;
        margin-left: 8px;
        .right_img_wrapper {
          display: block;
          margin-bottom: 8px;
          border-radius: 4px;
          overflow: hidden;
        }
      }
    }
    .home_worker_wrapper {
      width: 1200px;
      margin: 0 auto 32px;
      .home_worker_title {
        width: 100%;
        margin: 64px 0 32px;
        text-align: center;
        font-size: 36px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 600;
      }
      .worker_item {
        margin-bottom: 15px;
        width: 390px;
        height: auto;
        background-color: #fff;
        position: relative;
        cursor: pointer;
        border-radius: 4px;
        .worker_item_card {
          padding: 16px;
          display: block;
          width: 100%;
          .card_title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            .card_title_text {
              display: block;
              font-size: 18px;
              flex: 1 1;
              color: #111;
              letter-spacing: 0;
              padding-right: 8px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .card_content {
            .card_info {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 9px;
              line-height: 28px;
              .card_price {
                color: #42c088;
                font-size: 18px;
                font-weight: 600;
              }
              .card_time {
                font-size: 12px;
                color: rgba(0, 0, 0, 0.45);
              }
            }
          }

          .card_tag_list {
            flex: 1 1;
            display: flex;
            flex-wrap: wrap;
            height: 24px;
            overflow: hidden;
            .card_tag_item {
              flex-shrink: 0;
              width: -moz-fit-content;
              width: fit-content;
              max-width: 100px;
              height: 24px;
              font-size: 12px;
              line-height: 24px;
              padding: 0 8px;
              background: #f5f6fa;
              color: rgba(0, 0, 0, 0.65);
              border-radius: 2px;
              margin-right: 8px;
              display: -webkit-box;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }
        }
        .worker_bottom {
          width: 100%;
          height: 44px;
          background: linear-gradient(270deg, #fff, #f2f9ff);
          border-radius: 0 0 4px 4px;
          padding: 0 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .bottom_left {
            display: flex;
            align-items: center;
            .left_user_name {
              color: rgba(0, 0, 0, 0.65);
              max-width: 66px;
              padding: 0 4px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .YpLabel_ypLabel__2VFW5 {
              display: inline-block;
              padding: 0 6px;
              font-size: 12px;
              font-weight: 500;
              margin-right: 10px;
              border-radius: 2px;
              flex-shrink: 0;
            }
            .RecruitRecommendCard_label-smitem__TCFK4 {
              color: #46db7a !important;
              border: 1px solid #cfefd9;
              height: 20px;
              margin-right: 8px;
              background-color: #fff;
            }
            .RecruitRecommendCard_label-qyitem__cGFBJ {
              color: #0092ff !important;
              border: 1px solid #c8e7ff;
              height: 20px;
              margin-right: 8px;
            }
          }
          .bottom_right {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .right_button {
              width: 90px;
              height: 32px;
              border-radius: 4px;
              background-color: #fff;
              color: #42c088;
              border: 1px solid #42c088;
            }
          }
        }
      }
    }
  }
}
</style>
